import React, {FC, useRef, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {PrivateLayout} from "../../components/layout/PrivateLayout";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../components/datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import {useDeleteSupplierMutation, useGetSupplierCalculationsQuery, useGetSuppliersQuery} from "../../redux/api/suppliers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {RowActions} from "../../components/datagrid/RowActions";
import {AddButton} from "../../components/form/AddButton";
import {SupplierForm} from "./Suppliers/SupplierForm";
import {ConfirmDialog} from "../../components/dialogs/ConfirmDialog";
import {SupplierResponse} from "../../types/api/responses/supplier/SupplierResponse";

export const SuppliersPage: FC<{}> = () => {
    const [deleteSupplier] = useDeleteSupplierMutation();
    const [formOpen, setFormOpen] = useState(false);
    const [selectedForEditing, setSelectedForEditing] = useState<SupplierResponse|null>(null);
    const [selectedForDeleting, setSelectedForDeleting] = useState<SupplierResponse|null>(null);
    const gridRef = useRef<DynamicDataGridHandle|null>(null);
    const { data: calculations, isLoading } = useGetSupplierCalculationsQuery(
        selectedForDeleting ? `/api/suppliers/${selectedForDeleting.id}/calculations` : '0',
        { skip: !selectedForDeleting }
    );

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'relationCode',
            headerName: 'Relatiecode',
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Naam',
            minWidth: 400,
            flex: 1,
        },
        {
            field: 'shippingCosts.amount',
            headerName: 'Verzendkosten',
            flex: 1,
            renderCell: (params) => {
                // API either returns an empty object or an object without amount property if it's free
                if (!params.row.shippingCosts ||
                    Object.keys(params.row.shippingCosts).length === 0 ||
                    !params.row.shippingCosts.hasOwnProperty('amount')
                ) {
                    return '-';
                }

                return `${params.row.shippingCosts.currency} ${params.row.shippingCosts.amount.toFixed(2)}`;
            }
        },
        {
            field: 'noShippingCostsFrom.amount',
            headerName: 'Gratis vanaf',
            flex: 1,
            renderCell: (params) => {
                // API either returns an empty object or an object without amount property if it's free
                if (!params.row.noShippingCostsFrom ||
                    Object.keys(params.row.noShippingCostsFrom).length === 0 ||
                    !params.row.noShippingCostsFrom.hasOwnProperty('amount')
                ) {
                    return '-';
                }

                return `${params.row.noShippingCostsFrom.currency} ${params.row.noShippingCostsFrom.amount.toFixed(2)}`;
            }
        },
        {
            field: 'actions',
            headerName: 'Acties',
            flex: 1,
            renderCell: (params) => {
                const actions = [
                    {
                        icon: <EditIcon/>,
                        label: 'Wijzigen',
                        onClick: () => {
                            setSelectedForEditing(params.row);
                            setFormOpen(true);
                        }
                    },
                    {
                        icon: <DeleteForeverIcon/>,
                        label: 'Verwijderen',
                        onClick: async () => {
                            setSelectedForDeleting(params.row);
                        }
                    },
                ];

                return <RowActions actions={actions}/>;
            },
        },
    ];

    const handleCreateClick = () => {
        setSelectedForEditing(null);
        setFormOpen(true);
    }

    const handleCloseFormDialog = () => {
        setFormOpen(false);
        gridRef.current?.reload();
    }

    const handleDeleteConfirm = async () => {

        if (selectedForDeleting) {
            await deleteSupplier(selectedForDeleting.id).unwrap();
            setSelectedForDeleting(null);
            gridRef.current?.reload();
        }
    }
    const handleDeleteDecline = () => {
        setSelectedForDeleting(null);
    }

    const handleDeleteCalculations = () => {
        setSelectedForDeleting(null);
    }

    return (
        <PrivateLayout>
            <Grid container>
                <Grid item xs={12} sm={6} mb={3}>
                    <Typography component="h1" variant="h5">
                        Leveranciers
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                    <AddButton title="Leverancier toevoegen" onClick={handleCreateClick} />
                </Grid>

                <DynamicDataGrid
                    ref={gridRef}
                    columns={columns}
                    queryHook={useGetSuppliersQuery}
                    autoHeight={true}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={true}
                />
            </Grid>

            <SupplierForm
                action={null === selectedForEditing ? 'create' : 'edit'}
                open={formOpen}
                close={handleCloseFormDialog}
                initialValues={selectedForEditing}
            />

            {!isLoading && selectedForDeleting && calculations['hydra:member'].length === 0 && (
                <ConfirmDialog
                    title={'Leverancier verwijderen'}
                    message={`Weet je zeker dat je leverancier "${selectedForDeleting.name}" wilt verwijderen?`}
                    onYes={handleDeleteConfirm}
                    onNo={handleDeleteDecline}
                />
            )}

            {!isLoading && selectedForDeleting && calculations['hydra:member'].length > 0 && (
                <ConfirmDialog
                    title={'Leverancier verwijderen'}
                    message={`Verwijder eerst alle leveranciers bij de calculaties voordat je doorgaat. Leverancier is nog gekoppeld aan: 
                    ${calculations['hydra:member'].map((calculation: { name: string }) => ' ' + calculation.name)}`}
                    onYes={handleDeleteCalculations}
                    onNo={handleDeleteCalculations}
                />
            )}
        </PrivateLayout>
    );
}
