import React, {FC} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {LoginPage} from "./public/LoginPage";
import {SecurePage} from "../components/routing/SecurePage";
import {UsersPage} from "./private/admin/UsersPage";
import {SettingsPage} from "./private/admin/SettingsPage";
import {Error404Page} from "./public/Error404Page";
import {Error401Page} from "./public/Error401Page";
import {DeliveriesPage} from "./private/DeliveriesPage";
import {SuppliersPage} from "./private/SuppliersPage";
import {MarginGroupsPage} from "./private/MarginGroupsPage";
import {OptionGroupsPage} from "./private/OptionGroupsPage";
import {CalculationsPage} from "./private/CalculationsPage";
import {CalculationQuestionsPage} from "./private/CalculationQuestionsPage";
import {HandlingCostGroupsPage} from "./private/HandlingCostGroupsPage";
import {ShippingTimeGroupsPage} from "./private/ShippingTimeGroupsPage";
import {ResetPasswordPage} from "./public/ResetPasswordPage";
import {ForgotPasswordPage} from "./public/ForgotPasswordPage";
import {TwoFactorAuthenticationPage} from "./public/TwoFactorAuthenticationPage";
import {TwoFactorSetupPage} from "./public/TwoFactorSetupPage";
import {CalculationQuestions} from './private/Calculations/CalculationQuestions';
import {Question} from './private/Calculations/Question';

export const Router: FC<{}> = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* USER ROUTES */}
                <Route path="/" element={<SecurePage role="ROLE_USER"><CalculationsPage /></SecurePage>} />
                <Route path="/calculations" element={<SecurePage role="ROLE_USER"><CalculationsPage /></SecurePage>} />
                <Route path="/calculation/:id/questions" element={<SecurePage role="ROLE_USER"><CalculationQuestions /></SecurePage>} />
                <Route path="/calculation/:id/question/:questionId" element={<SecurePage role="ROLE_USER"><Question /></SecurePage>} />
                {/*<Route path="/calculation-questions" element={<SecurePage role="ROLE_USER"><CalculationQuestionsPage /></SecurePage>} />*/}
                <Route path="/deliveries" element={<SecurePage role="ROLE_USER"><DeliveriesPage /></SecurePage>} />
                {/*<Route path="/margin-groups" element={<SecurePage role="ROLE_USER"><MarginGroupsPage /></SecurePage>} />*/}
                <Route path="/handling-cost-groups" element={<SecurePage role="ROLE_USER"><HandlingCostGroupsPage /></SecurePage>} />
                <Route path="/option-groups" element={<SecurePage role="ROLE_USER"><OptionGroupsPage /></SecurePage>} />
                <Route path="/shipping-time-groups" element={<SecurePage role="ROLE_USER"><ShippingTimeGroupsPage /></SecurePage>} />
                <Route path="/suppliers" element={<SecurePage role="ROLE_USER"><SuppliersPage /></SecurePage>} />

                {/* ADMIN ROUTES */}
                <Route path="/admin/settings" element={<SecurePage role="ROLE_ADMIN"><SettingsPage /></SecurePage>} />
                <Route path="/admin/users" element={<SecurePage role="ROLE_ADMIN"><UsersPage /></SecurePage>} />

                {/* PUBLIC ROUTES */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/2fa/authenticate" element={<TwoFactorAuthenticationPage />} />
                <Route path="/2fa/setup" element={<TwoFactorSetupPage />} />
                {/*<Route path="/" element={<LoginPage />} />*/}
                <Route path="/error/not-allowed" element={<Error401Page />} />
                <Route path="*" element={<Error404Page />} />
            </Routes>
        </BrowserRouter>
    );
}
