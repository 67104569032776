import React, {FC, useEffect, useState} from "react";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {useFormik} from "formik";
import ArrayHelper from "../../../helpers/ArrayHelper";
import {useGetMagento2AttributesQuery} from "../../../redux/api/magento2";
import {SelectField} from "../../../components/form/SelectField";
import {AttributeResponse} from "../../../types/api/responses/magento2/AttributeResponse";
import {TextField} from "../../../components/form/TextField";
import {AutocompleteMultipleWithNewField} from "../../../components/form/AutocompleteMultipleWithNewField";
import {AutocompleteMultipleField} from "../../../components/form/AutocompleteMultipleField";
import {useDeleteCalculationAttributeMutation} from "../../../redux/api/calculation-attributes";
import {AutocompleteField} from '../../../components/form/AutocompleteField';

export type CalculationFormAttributesProps = {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
};

export const CalculationFormAttributes: FC<CalculationFormAttributesProps> = ({formik}) => {
    const {data: magento2Attributes} = useGetMagento2AttributesQuery();
    const [extraRowData, setExtraRowData] = useState({});
    const [deleteAttribute] = useDeleteCalculationAttributeMutation();

    const addExtraRow = () => {
        setExtraRowData({});

        if (!ArrayHelper.get(formik.values, 'attributes', false)) {
            formik.setFieldValue('attributes', [extraRowData]);
        } else {
            formik.setFieldValue('attributes', [...ArrayHelper.get(formik.values, 'attributes', false), extraRowData]);
        }
    }

    const deleteRow = (index: number) => {
        const newRows = ArrayHelper.get(formik.values, 'attributes', false).filter((row: any, i: number) => i !== index);
        formik.setFieldValue('attributes', newRows);

        const attribute = ArrayHelper.get(formik.values, 'attributes', false);
        deleteAttribute(attribute[index].id)
    }

    const getAttributeData = (code: string): AttributeResponse | undefined => {
        return magento2Attributes && magento2Attributes['hydra:member']
            .find(attribute => attribute.attribute_code === code);
    }

    return (
        <TableContainer component={Paper}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Waarde</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ArrayHelper.get(formik.values, 'attributes', false)
                        && ArrayHelper.get(formik.values, 'attributes', false).map((data: any, index: number) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <SelectField
                                        formik={formik}
                                        label={' '}
                                        name={`attributes.${index}.code`}
                                        options={magento2Attributes
                                            && magento2Attributes['hydra:member'].map(attribute => ({label: attribute.frontend_label, value: attribute.attribute_code}))}
                                    />
                                </TableCell>
                                <TableCell>
                                    {magento2Attributes && (
                                        <>
                                            {getAttributeData(data.code)?.type === 'text' && (
                                                <TextField
                                                    formik={formik}
                                                    label={' '}
                                                    name={`attributes.${index}.value`}
                                                />
                                            )}
                                            {getAttributeData(data.code)?.type === 'select' && getAttributeData(data.code)?.options !== undefined && (
                                                <AutocompleteField
                                                    formik={formik}
                                                    label={' '}
                                                    name={`attributes.${index}.value`}
                                                    options={getAttributeData(data.code)!.options.map(option => ({ label: option.label, value: option.value }))}
                                                />
                                            )}
                                            {getAttributeData(data.code)?.type === 'boolean' && (
                                                <SelectField
                                                    formik={formik}
                                                    label={' '}
                                                    name={`attributes.${index}.value`}
                                                    options={getAttributeData(data.code)?.options?.filter(option => option.value && option.value.trim() !== '')
                                                        .map(option => ({
                                                            label: option.label,
                                                            value: option.value
                                                        }))
                                                    }
                                                />
                                            )}
                                            {getAttributeData(data.code)?.type === 'multiselect' && (
                                                <AutocompleteMultipleField
                                                    formik={formik}
                                                    label={' '}
                                                    name={`attributes.${index}.value`}
                                                    options={getAttributeData(data.code)?.options?.filter(option => option.value && option.value.trim() !== '')
                                                        .map(option => ({
                                                            label: option.label,
                                                            value: option.value
                                                        })) ?? []
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={"Rij verwijderen"}>
                                        <IconButton onClick={() => deleteRow(index)}>
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>
                            <Tooltip title={"Nieuwe rij toevoegen"}>
                                <IconButton onClick={() => addExtraRow()}>
                                    <AddCircleOutlineOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
