import React, {FC} from 'react';
import {useFormik} from "formik";
import {SettingsCard} from "./SettingsCard";
import {RepeatableField} from "../../../../components/form/RepeatableField";
import {DatePicker} from "@mui/x-date-pickers";
import {AutocompleteMultipleField, AutocompleteOption} from "../../../../components/form/AutocompleteMultipleField";
import {useGetSuppliersQuery} from "../../../../redux/api/suppliers";
import moment from "moment";
import ArrayHelper from "../../../../helpers/ArrayHelper";
import {Alert, CircularProgress, Grid} from "@mui/material";

export type DeliveryFormProps = {
    formik: ReturnType<typeof useFormik>
}

export const DeliveryForm: FC<DeliveryFormProps> = ({formik}) => {
    const {data: suppliers} = useGetSuppliersQuery({page: 1, pageSize: 1000});

    let suppliersOptions: Array<AutocompleteOption> = [];
    if (suppliers) {
        suppliersOptions = suppliers['hydra:member'].map((supplier) => ({
            label: supplier.name,
            value: ''+supplier.id,
        }));
    }

    if (!suppliersOptions || 0 === suppliersOptions.length) {
        return (
            <Grid container justifyContent="center" alignContent="center" height="100%" width="100%">
                <CircularProgress size={60} />
            </Grid>
        );
    }

    return (
        <>
            <SettingsCard title="Levertijd afwijkingen">
                <Alert sx={{my: '10px'}} severity="info">
                    Dit zijn extra levertijden. Deze kunnen gebruikt worden als
                    een leverancier bijvoorbeeld 2 dagen dicht is. Dan kunnen er
                    2 extra dagen levertijd opgeteld worden. De extra levertijd
                    wordt pas opgeteld, na de standaard levertijd.<br />

                    <b>Bijvoorbeeld:</b> <br />
                    Besteldatum: 11 Oktober<br />
                    Standaard levering: 5 werkdagen<br />
                    Afwijkende levertijd: 14 Oktober t/m 18 Oktober<br /><br />

                    11 Oktober + 5 werkdagen = 18 Oktober<br />
                    14 Oktober t/m 18 Oktober = 5 werkdagen.<br /><br />

                    5 + 5 = 10 Werkdagen levertijd totaal.<br /><br />

                    Als de bestelling midden in de afwijking valt, gaat hij pas
                    tellen op het moment van de bestelling. <br />

                    Als er <b>geen</b> leverancier geselecteerd is, telt de
                    extra levertijd voor alle calculaties.
                </Alert>
                <RepeatableField
                    baseName={'delivery/excluded/date_ranges'}
                    formik={formik}
                    columns={[
                        {
                            name: 'from',
                            label: 'Van *',
                            field: DatePicker,
                            fieldProps: (row) => ({
                                onChange: (value: any) => formik.setFieldValue(`delivery/excluded/date_ranges.${row}.from`, value),
                                defaultValue: moment(ArrayHelper.get(formik.values, `delivery/excluded/date_ranges.${row}.from`)),
                                format: 'DD-MM-YYYY',
                                slotProps: {
                                    textField: {
                                        variant: 'standard',
                                        size: 'small',
                                        margin: 'normal',
                                    }
                                }
                            })
                        },
                        {
                            name: 'until',
                            label: 'Tot en met *',
                            field: DatePicker,
                            fieldProps: (row) => ({
                                onChange: (value: any) => formik.setFieldValue(`delivery/excluded/date_ranges.${row}.until`, value),
                                defaultValue: moment(ArrayHelper.get(formik.values, `delivery/excluded/date_ranges.${row}.until`)),
                                format: 'DD-MM-YYYY',
                                slotProps: {
                                    textField: {
                                        variant: 'standard',
                                        size: 'small',
                                        margin: 'normal',
                                    }
                                }
                            })
                        },
                        {
                            name: 'suppliers',
                            label: 'Alleen voor deze leveranciers',
                            field: AutocompleteMultipleField,
                            fieldProps: (row) => ({
                                options: suppliersOptions,
                            }),
                        },
                    ]}
                />
            </SettingsCard>
        </>
    );
}
