import React, {FC} from "react";
import {Autocomplete, AutocompleteProps, TextField} from "@mui/material";
import {useFormik} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";

export type AutocompleteFieldOption = {
    value?: number|string,
    label: string,
}

export type AutocompleteFieldProps = Omit<AutocompleteProps<AutocompleteFieldOption, true, false, true> & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
    label: string,
    required?: boolean,
    options: Array<AutocompleteFieldOption>,
}, 'renderInput'>

export const AutocompleteField: FC<AutocompleteFieldProps> = ({formik, name, label, required, options, ...restProps}) => {
    return (
        <Autocomplete
            disablePortal
            options={options}
            getOptionLabel={(option: any) => {
                if(option.label){
                    return option.label;
                }

                return options.find((opt: any) => opt.value === option)?.label;
            }}

            isOptionEqualToValue={(option: any, value: any) => {
                if (option.value === value.value) {
                    return true;
                }

                return false;
            }}

            // @ts-ignore
            value={ArrayHelper.get(formik.values, name) || null}
            // @ts-ignore
            onChange={(event, newValue) => formik.setFieldValue(name, newValue ? newValue.value : null)}
            renderInput={(params) => (
                <TextField
                    required={required}
                    {...params}
                    variant="standard"
                    label={label}
                />
            )}
            {...restProps}
        />
    );
}
