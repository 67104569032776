import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {SuppliersResponse} from "../../types/api/responses/supplier/SuppliersResponse";
import {SupplierResponse} from "../../types/api/responses/supplier/SupplierResponse";
import {SupplierRequest} from "../../types/api/requests/supplier/SupplierRequest";

const suppliersApi = api.injectEndpoints({
    endpoints: build => ({
        getSuppliers: build.query<SuppliersResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/suppliers', request),
            providesTags: ['suppliers'],
        }),

        getSupplier: build.query<SupplierResponse, string>({
            query: (url) => ({
                url: url,
                method: 'GET',
            }),
        }),

        getSupplierCalculations: build.query<any, string>({
            query: (url) => ({
                url: url,
                method: 'GET',
            }),
        }),

        createSupplier: build.mutation<SupplierResponse, SupplierRequest>({
            query: (supplier) => ({
                url: '/api/suppliers',
                method: 'POST',
                body: supplier,
            }),
            invalidatesTags: ['suppliers']
        }),

        updateSupplier: build.mutation<SupplierResponse, SupplierRequest>({
            query: (supplier) => ({
                url: `/api/suppliers/${supplier.id}`,
                method: 'PUT',
                body: supplier,
            }),
            invalidatesTags: ['suppliers']
        }),

        deleteSupplier: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/suppliers/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['suppliers']
        }),
    }),
});

export const {
    useGetSuppliersQuery,
    useGetSupplierQuery,
    useGetSupplierCalculationsQuery,
    useCreateSupplierMutation,
    useUpdateSupplierMutation,
    useDeleteSupplierMutation,
} = suppliersApi;

export default suppliersApi;
