import React, {FC} from "react";
import {SelectField, SelectFieldProps} from "./SelectField";
import {useGetMagento2CmsBlocksQuery} from "../../redux/api/magento2";
import {CmsBlockResponse} from "../../types/api/responses/magento2/CmsBlockResponse";

export type CmsBlockFieldProps = Omit<SelectFieldProps, "options">;

export const CmsBlockField: FC<CmsBlockFieldProps> = (props) => {
    const {data: cmsBlocks} = useGetMagento2CmsBlocksQuery();

    return (
        <SelectField
            allowEmpty
            options={cmsBlocks && cmsBlocks['hydra:member'].map((block: CmsBlockResponse) => ({label: block.name, value: block.id}))}
            {...props}
        />
    );
}
