import React, {FC} from "react";
import {Divider, List} from "@mui/material";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {MainMenuItem} from "./MainMenuItem";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import SailingOutlinedIcon from '@mui/icons-material/SailingOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';

export const MainMenu: FC<{}> = () => {
    return (
        <>
            <List>
                <MainMenuItem to="/calculations" icon={<CalculateOutlinedIcon/>} title={'Calculaties'} role={'ROLE_USER'} />
                {/*<MainMenuItem to="/calculation-questions" icon={<QuizOutlinedIcon/>} title={'Calculatie vragen'} role={'ROLE_USER'} />*/}
                <MainMenuItem to="/deliveries" icon={<LocalShippingOutlinedIcon/>} title={'Leveringen'} role={'ROLE_USER'} />
                {/*<MainMenuItem to="/margin-groups" icon={<PercentOutlinedIcon/>} title={'Margegroepen'} role={'ROLE_USER'} />*/}
                <MainMenuItem to="/option-groups" icon={<TuneOutlinedIcon/>} title={'Optiegroepen'} role={'ROLE_USER'} />
                <MainMenuItem to="/handling-cost-groups" icon={<PriceChangeOutlinedIcon/>} title={'Toeslag groepen'} role={'ROLE_USER'} />
                <MainMenuItem to="/shipping-time-groups" icon={<SailingOutlinedIcon/>} title={'Levertijd toeslag groepen'} role={'ROLE_USER'} />
                <MainMenuItem to="/suppliers" icon={<FactoryOutlinedIcon/>} title={'Leveranciers'} role={'ROLE_USER'} />
            </List>
            <Divider/>
            <List>
                <MainMenuItem to="/admin/users" icon={<PeopleAltOutlinedIcon/>} title={'Gebruikers'} role={'ROLE_ADMIN'} />
                <MainMenuItem to="/admin/settings" icon={<SettingsApplicationsOutlinedIcon/>} title={'Instellingen'} role={'ROLE_ADMIN'} />
            </List>
        </>
    );
}
